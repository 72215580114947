import { Button, Popover, Tooltip } from 'antd';
import Flexbox from 'components/core/Flexbox';
import PcmSearchkitManager from 'components/page/search/components/PcmSearchkitManager';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Hits,
  HitsStats,
  InitialLoader,
  ItemComponent,
  NoHits,
  ResetFilters,
  SearchBox,
  SearchkitProvider,
  SelectedFilters,
  SortingSelector
} from 'searchkit';
import { CustomPanel } from './components/CustomPanel';
import CustomRefinementListFilter from './components/CustomRefinementListFilter';
import RepoHitResult from './components/RepoHitResult';

import { SearchContainer } from 'components/page/search/components/SearchStyles';
import 'searchkit/release/theme.css';
import './styles.less';

export const trlToolip = (<>Technology Readiness Level <a href="/about"><i className="fa-solid fa-circle-question" style={{color: 'white'}}></i></a></>);

const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
let searchkitManager = null;



//added a tooltip
//also I'm not sure why showCheckbox was getting set to false when I set the ItemComponent prop so having to manually set that too
const ItemComponentWithCheckboxTooltip = props => {
  const newProps = { ...props };
  newProps.showCheckbox = true;
  newProps.label = <Tooltip title={props.label}>{props.label}</Tooltip>;
  return (
    <Tooltip title={newProps.label}>
      <ItemComponent {...newProps} />
    </Tooltip>
  );
};


const hitsLoader = () => (
  <div className="loading-container">
    <div className="spinner-container">
      <i className="fa fa-spinner fa-pulse" aria-hidden="true" />
      Loading..
    </div>
  </div>
);

export default class Search extends React.Component {
  static propTypes = {
    userLoggedIn: PropTypes.bool,
  };

  componentWillMount() {
    const sessionId = localStorage.getItem('sessionId');
    searchkitManager = new PcmSearchkitManager(`${baseUrl}/es/repo_v2`, {
      httpHeaders: sessionId ? { 'PCM-Session-ID': sessionId } : {},
    });
  }

  getResultItem = props => {
    //resort the items array to be sorted by key instead of what searchkit's default sorting is
    return <RepoHitResult result={props.result} />;
  };

  
  mainQueryBuilder = query => {
    query = _.trim(query.toLowerCase());
    const isPhrase = query.startsWith('"') && query.endsWith('"');
    
    // dont add * wildcards to terms when special characters like - are used
    // or queries do not work
    const specialCharRegex = /[+|*\-()~]/;
  
    const terms = query.split(/\s+/);
  
    let nameQueries = {
      match_phrase: {
        title: query,
      },
    };
  
    if (!isPhrase) {
      nameQueries = terms.map(word => {
        if (specialCharRegex.test(word)) {
          return {
            match: {
              title: word,
            },
          };
        }
        return {
          wildcard: {
            title: { value: `*${word}*`, boost: 1 },
          },
        };
      });
    }
  
    let descQueries = {
      match_phrase: {
        description: query,
      },
    };
  
    if (!isPhrase) {
      descQueries = terms.map(word => {
        if (specialCharRegex.test(word)) {
          return {
            match: {
              description: word,
            },
          };
        }
        return {
          wildcard: {
            description: { value: `*${word}*`, boost: 1 },
          },
        };
      });
    }
  
    const queries = _.concat(nameQueries, descQueries);
    return this.buildQuery(queries);
  };
  

  buildQuery = queries => ({
    function_score: {
      query: {
        bool: {
          should: queries,
          minimum_should_match: 1,
        },
      },
      boost_mode: 'multiply',
    },
  });

  render() {
    return (
      <React.Fragment>
        <div className={SearchContainer}>
          <span style={{display:'none'}}>latest</span>
          <SearchkitProvider searchkit={searchkitManager}>
            <div className="search-kit-container">
              <div className="search-box-container">
                <SearchBox
                  autofocus
                  searchOnChange={true}
                  queryBuilder={this.mainQueryBuilder}
                />
                <Flexbox
                  className="include-search"
                  flexDirection="row"
                  alignItems="center"
                >
                </Flexbox>
              </div>
              <div className="filter-panel">
                
                <div className="filter-container">
                  <CustomRefinementListFilter
                    id="technology"
                    title="Technology"
                    field="technology.keyword"
                    operator="OR"
                  />
                </div>


                <div className="filter-container">
                  <CustomRefinementListFilter
                    id="repo-language"
                    title="Language"
                    field="programming_language.keyword"
                    operator="OR"
                  />
                </div>

                <div className="filter-container">
                  <CustomRefinementListFilter
                    id="non_open_source_license"
                    title="Non-Open Source License"
                    field="other_license.keyword"
                    operator="OR"
                  />
                </div>
                <div className="filter-container">
                  <CustomRefinementListFilter
                    id="open_source_license"
                    title="Open Source License"
                    field="open_source_license.keyword"
                    operator="OR"
                  />
                </div>

                
                <div className="filter-container">
                  <CustomRefinementListFilter
                    id="cost"
                    title="Cost"
                    field="cost.keyword"
                    operator="OR"
                  />
                </div>
                <div className="filter-container">
                  <CustomRefinementListFilter
                    id="trl"
                    title="TRL"
                    field="trl.keyword"
                    operator="OR"
                    containerComponent={CustomPanel}
                    tooltip={trlToolip}
                  />
                </div>
                <div className="filter-container">
                  <CustomRefinementListFilter
                    id="collection_method"
                    title="Collection Method"
                    field="collection_method.keyword"
                    operator="OR"
                  />
                </div>
                <div className="filter-container">
                  <CustomRefinementListFilter
                    id="life_cycle"
                    title="Life Cycle"
                    field="life_cycle.keyword"
                    operator="OR"
                    itemComponent={ItemComponentWithCheckboxTooltip}
                  />
                </div>
              </div>
              <div className="hits-container">
                <div className="hits-header">
                  <HitsStats
                    translations={{
                      'hitstats.results_found': '{hitCount} repositories found',
                    }}
                  />
                  <div className="sort-by">Sort By:</div>
                  <SortingSelector
                    options={[
                      {
                        label: 'Most Recent',
                        key: 'updated_at_desc',
                        fields: [
                          {
                            field: 'updated_at',
                            options: { order: 'desc' },
                          },
                          { field: '_score', options: { order: 'desc' } },
                        ],
                        defaultOption: true,
                      },
                      {
                        label: 'Alphabetical',
                        key: 'repo',
                        fields: [
                          {
                            field: 'repo_name.keyword',
                            options: { order: 'asc' },
                          },
                          { field: '_score', options: { order: 'desc' } },
                        ],
                      },
                      {
                        label: 'Relevance',
                        key: 'relevance',
                        field: '_score',
                        order: 'desc',
                      },
                      {
                        label: 'Most Open Issues Count',
                        key: 'open_issue_desc',
                        fields: [
                          {
                            field: 'issue_open_count',
                            options: { order: 'desc' },
                          },
                          { field: '_score', options: { order: 'desc' } },
                        ],
                      },
                      {
                        label: 'Least Open Issues Count',
                        key: 'open_issue_asc',
                        fields: [
                          {
                            field: 'issue_open_count',
                            options: { order: 'asc' },
                          },
                          { field: '_score', options: { order: 'desc' } },
                        ],
                      },
                      {
                        label: 'Contributor count',
                        key: 'contributor_count_desc',
                        fields: [
                          {
                            field: 'contributor_count',
                            options: { order: 'desc' },
                          },
                          { field: '_score', options: { order: 'desc' } },
                        ],
                      },
                      {
                        label: 'Fork count',
                        key: 'fork_count_desc',
                        fields: [
                          {
                            field: 'fork_count',
                            options: { order: 'desc' },
                          },
                          { field: '_score', options: { order: 'desc' } },
                        ],
                      },
                      {
                        label: 'Downloads',
                        key: 'download_count_desc',
                        fields: [
                          {
                            field: 'downloads_last_month',
                            options: { order: 'desc' },
                          },
                          { field: '_score', options: { order: 'desc' } },
                        ],
                      },
                    ]}
                  />
                </div>
                <div className="hits-sub-header">
                  <SelectedFilters />
                  <div className="reset-expand-all-container">
                    <ResetFilters />
                  </div>
                </div>
                <Hits
                  hitsPerPage={10000}
                  sourceFilter={[
                    'collection_method',
                    'discipline',
                    'interface',
                    'life_cycle',
                    'logo_url',
                    'method',
                    'primary_use',
                    'trl',
                    'technology',
                    'title',
                    'other_license',
                    'open_source_license',
                    'web_address',
                    'cost',
                    'funding',
                    'programming_language',
                    'cost',
                    
                    'org_name',
                    'repo_name',
                    
                    'description',
                    'updated_at',
                    'contributor_count',
                    'issue_open_count',
                    'issue_closed_count',
                    'pr_open_count',
                    'pr_closed_count',
                    'release_count',
                    'release_tag',
                    'release_date',
                    'fork_count',
                    'commit_activity',
                    'badges',
                    'downloads_last_day',
                    'downloads_last_month',
                    'downloads_last_week',
                  ]}
                  mod="sk-hits-list"
                  // This needs to be arrow function to cause a change in the prop and force re-render
                  // eslint-disable-next-line react/jsx-no-bind
                  itemComponent={props => this.getResultItem(props)}
                />
                <NoHits
                  translations={{
                    'NoHits.NoResultsFound': "No results found for '{query}'.",
                  }}
                />
                <InitialLoader component={hitsLoader} />
                {/* removed pagination for now since there are less than 50 repos total */}
                {/* <PcmPagination
                  showNumbers
                  translations={{
                    'pagination.previous': 'Previous Page',
                    'pagination.next': 'Next Page',
                  }}
                /> */}
              </div>
            </div>
          </SearchkitProvider>
        </div>
      </React.Fragment>
    );
  }
}
